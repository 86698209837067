<template>
  <App
    title='搜索'
    left-arrow
  >
    <form action="/">
      <Search
        v-model="tel"
        placeholder="请输入手机号"
        input-align="center"
        maxlength="11"
        @search="onSearch"
      ></Search>
    </form>
    <List
      v-model="loading"
      :finished="finished"
      :finished-text="list.length === 0 ? '暂无数据' : 'No more items.'"
    >
      <CardItem
        v-for="$items in list"
        :key="$items.id"
        :cardInfo="$items"
        @click.native="detail($items)"
      />
    </List>

  </App>
</template>

<script>
import { Search, List, Toast } from 'vant';
import { tabsData } from './attr.js'
import CardItem from './components/card/index'
import 'vant/lib/nav-bar/style'
import '@/style/vant-patch.less'
// import './index.less'
// import dayjs from 'dayjs'

import { searchTestdrive } from '@/api/testdrive/index'
export default {
  name: 'testdriveSearch',
  components: {
    Search,
    List,
    CardItem
  },
  data () {
    return {
      tel: '',
      loading: false,// 下拉刷新时的loading是否展示
      finished: true,
      tabsData: [], // 排程状态集合
      list: []
    }
  },
  methods: {
    // 点击跳转到详情的事件
    detail (item) {
      const id = item.testDriveId
      this.$router.push({
        path: '/testDriveDetail',
        query: { id }
      })
    },
    // 用户点击键盘上搜索按钮的事件
    onSearch () {
      const reg = /^1\d{10}$/
      if (reg.test(this.tel)) {
        this.getTestdriveList()
      } else {
        Toast('请输入正确格式的手机号')
      }

    },
    // 获取排程列表
    async getTestdriveList () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const res = await searchTestdrive({
        accountPhone: this.tel,
        page: 1,
        size: 50
      })
      Toast.clear()
      if (res.success) {
        if (res.data.records.length) {
          res.data.records.forEach(item => {
            this.tabsData.forEach(el => {
              if (item.testDriveStatus === el.label) {
                item['tag'] = el.title
              }
            })
          })
          this.list = [...res.data.records]
        } else {
          this.list = []
        }
      } else {
        Toast(res.msg)
      }

    },
    init () {
      this.tabsData = tabsData
      this.tel = ''
      this.list = []
    },
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>
.search-null {
  text-align: center;
  line-height: 2em;
}
</style>